import {useEffect, useState} from 'react'
import {Box, Hdiv, Arrow, AccentBox, Text} from './common/common.styles'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'

const ArrowHolder = styled(AccentBox)`
	margin-top: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	width: 1.5rem;
	height: 3rem;
	flex-shrink: 0;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	&:hover {
		background: ${props => props.theme.gold[4]};
	}
`;
const NavList = styled(Box)`
	border-right: 2px solid ${props => props.theme.gold[3]};
	width: 12rem;
	box-sizing: border-box;
	height: 100%;
`;
const NavItem = styled(Text)`
	font-size: 1.2rem;
	margin: 0px;
	padding: 6px 0;
	border-bottom: 1px solid grey;
	*:last-child > & {
		border: none;
	}
`;
const NavItemPadding = styled(Box)`
	padding: 0 12px;
	cursor: pointer;
	width: 100%;
	box-sizing: border-box;
	&:hover {
		background: ${props => props.theme.grey[2]}
	}
	&:first-child {
		padding-top: 6px;
	}
	&:last-child {
		padding-bottom: 6px;
	}
`;
const NavContainer = styled(Hdiv)`
	position: fixed;	
	top: 5.14rem;
	left: 0px;
	background: ${props => props.theme.grey[1]};
	height: 100%;
	z-index: 2;
`;
interface SideNavProps {
	open: boolean
	setOpen: Function
}
export function SideNav(props: SideNavProps) {

	useLocation() // Allows update everytime location changes

	var [h2s,setH2s] = useState<HTMLHeadingElement[]>([])

	function inner_equal(a: Array<HTMLHeadingElement>,b: Array<HTMLHeadingElement>) {
		if (a.length != b.length) {
			return false
		}
		for (let i=0; i<a.length; i++) {
			if (a[i].innerText != b[i].innerText) {
				return false
			}
		}
		return true
	}

	useEffect(() => {
		window.requestAnimationFrame(() => {
		var newH2s = Array.from(document.querySelectorAll("h2"))
		if (!inner_equal(h2s,newH2s)) {
			setH2s(newH2s)
		}
	})})
	var navItems = h2s.map((i) => {
		return (
			<NavItemPadding onClick={() => {i.scrollIntoView(); window.scrollBy(0,-100)}}>
				<NavItem>{i.innerText}</NavItem>
			</NavItemPadding>
		)
	})

	if (props.open) {
		return (
			<NavContainer>
				<NavList>
					{navItems}
				</NavList>
				<ArrowHolder onClick={() => {props.setOpen(false)}}>
					<Arrow margin="4px 0 0 -0.6rem" color="white" turn={270} ></Arrow>
				</ArrowHolder>
			</NavContainer>
	)}
	return (
		<NavContainer>
			<ArrowHolder onClick={() => {props.setOpen(true)}}>
				<Arrow margin="4px 0 0 -0.6rem" color="white" turn={90}></Arrow>
			</ArrowHolder>
		</NavContainer>
	)
}