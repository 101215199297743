import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Main, Box, Hdiv, Text, H2, Logo, Arrow, AccentBox } from './common/common.styles' 
import useViewport from './common/useViewport';

interface APICardProps {
	name: string,
	description: string,
	img: string,
	pageLink: string
};
const ColMain = styled(Main)`
	display: flex;
	flex-direction: row;
`;
const Card = styled(Box)`
	padding: 8px;
	border-radius: 8px;
	border: 2px solid ${props => props.theme.gold[3]};
	align-items: flex-start;
	margin-top: 16px;
	box-shadow: 0px 3px 4px -1px grey;
	:first-child {
		margin-top: 0px;
	}
`;
const ProductContainer = styled(Box)`
	transition: margin-top 1s;
	transition-timing-function: ease-out;
	margin-top: 80px;
	margin-left: 8px;
	&.up {
		margin-top: 0px;
	}
`;
const APICardImg = styled(Logo)`
	height: 2rem;
`;
function Home() {

	var ref = useRef(null)
	useEffect(() => {
		var c: any = ref.current
		if (c !== null) {
			var yo = c.classList.add("up")
		}
	})
	if (useViewport() < 640) {
		return (
			<Main>
				<Box>
					<MiniAPICard
						img="qrcode.svg"
						name="QRcodes"	
						description=""
						pageLink='/qrcode'
					></MiniAPICard>
				</Box>
				<Box>
					<Text>Goldware is Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo deserunt iste recusandae ipsa est quibusdam enim expedita blanditiis ut quo pariatur temporibus tempora voluptate error laudantium, impedit consequatur maxime odio.</Text>
					<H2 id="Billing">Billing</H2>
					<Text>All payment and subscriptions are handled through Rapid API.</Text>
					<H2 id="Privacy">Privacy</H2>
					<Text>Goldware do not store any sensitive userdata. However sensitive data may be processed.</Text>
				</Box>
			</Main>
		)
	}  
	return (
		<ColMain>
			<Box>
				<Text>Goldware is Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo deserunt iste recusandae ipsa est quibusdam enim expedita blanditiis ut quo pariatur temporibus tempora voluptate error laudantium, impedit consequatur maxime odio.</Text>
				<H2 id="Billing">Billing</H2>
				<Text>All payment and subscriptions are handled through Rapid API.</Text>
				<H2 id="Privacy">Privacy</H2>
				<Text>Goldware do not store any sensitive userdata. However sensitive data may be processed.</Text>
			</Box>
			<ProductContainer ref={ref}>
				<APICard 
					img="qrcode.svg" 
					name="QRcodes" 
					description="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo deserunt iste recusandae ipsa est quibusdam enim expedita blanditiis ut quo pariatur temporibus tempora voluptate error laudantium, impedit consequatur maxime odio."
					pageLink='/qrcode'
				></APICard>
			</ProductContainer>
		</ColMain>
	)
}
const HCard = styled(Card)`
	display: flex;
	flex-direction: row;
	align-items: stretch;
`; 
const MiniH2 = styled(H2)`
	margin: 0;
`;
const APICardBody = styled(Hdiv)`
	flex-wrap: wrap;
`;
const APIArrow = styled(Link)`
	align-self: end;
	flex-grow: 0;
`;
const APIDesc = styled(Text)`
	flex-basis: 240px;
	flex-grow: 1;
	margin: 0;
`;
function MiniAPICard(props: APICardProps) {
	return (
		<HCard>
			<APICardImg src={props.img}/>
			<MiniH2 as="h3">{props.name}</MiniH2> {/*Stops it being grabbed by the navbar*/}
			<Link to={props.pageLink}>
				<Arrow turn={90} color="gold" margin="0.2rem 0 0 0"></Arrow>
			</Link>
		</HCard>
	)
}
function APICard(props: APICardProps) {
	return (
		<Card>
			<Hdiv>
				<APICardImg src={props.img}/>
				<H2 as="h3">{props.name}</H2> {/*Stops it being grabbed by the navbar*/}
			</Hdiv>
			<APICardBody>
				<APIDesc>{props.description}</APIDesc>
				<APIArrow to={props.pageLink}>
					<Arrow turn={90} color="gold" margin="0.2rem -0.5rem 0 -1rem"></Arrow>
				</APIArrow>
			</APICardBody>
		</Card>
	)
}

export default Home