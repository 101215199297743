import styled from 'styled-components' 

export const Text = styled.p`
	margin: 4px 0;
	color: ${props => props.theme.grey[10]};
	font-family: sans-serif;
	text-size-adjust: none;
`;
export const Box = styled.div`
	background: ${props => props.theme.grey[1]};
`;
export const AccentText = styled(Text)`
	padding: 4px;
	margin: 0px;
	color: ${props => props.theme.grey[0]};
	background: ${props => props.theme.gold[3]};
`;
export const AccentBox = styled.div` // Should share styles with Box but not inherit
	background: ${props => props.theme.gold[3]};
`;
export const Hdiv = styled.div`
	display: flex;
	flex-direction: row;
`;
export const Himg = styled.img`
	height: 100%;
`;
export const Main = styled(Box)`
	padding: 16px;
`;
export const H2 = styled.h2` // Needs to be h2 for selectors
	margin: 8px 0;
	color: ${props => props.theme.grey[10]};
	font-family: sans-serif;
	font-size: 1.5rem;
	text-size-adjust: none;
`;
export const Logo = styled(Himg)`
  margin-right: 8px;
`;
export var theme = {
	// // fg: "#000000",
	// // fgAccent: "#FFFFFF",
	// // bg: "#FFFFFF",
	// // accent: "silver",
	// // accentH: "grey",
	// // bar: "#101010",
	// // barH: "#202020" 
	// fg: "#000",
	// fgAccent: "#ffffff",
	// bg: "#eeeeee",
	// accent: "#d4af37",
	// accentH: "#b1a034",
	// barH: "#cccccc"
	// grey: [
	// 	"#ffffff",
	// 	"#e6e6e6",
	// 	"#cccccc",
	// 	"#b3b3b3",
	// 	"#999999",
	// 	"#808080",
	// 	"#666666",
	// 	"#4d4d4d",
	// 	"#333333",
	// 	"#191919",
	// 	"#000000"
	// ],
	grey: [
		"rgb(255, 255, 255)",
		"rgb(250, 250, 250)",
		"rgb(242, 242, 242)",
		"rgb(230, 230, 230)",
		"rgb(213, 213, 213)",
		"rgb(190, 190, 190)",
		"rgb(160, 160, 160)",
		"rgb(123, 123, 123)",
		"rgb(81, 81, 81)",
		"rgb(39, 39, 39)",
		"rgb(0,0,0)"
	],
	// gold: [
	// 	"#ffffff",
	// 	"#ffeeb0",
	// 	"#ffdc5f",
	// 	"#ffc90e",
	// 	"#e2af00",
	// 	"#c59900",
	// 	"#a88300",
	// 	"#8a6c00",
	// 	"#503f00",
	// 	"#332800"
	// ]
	gold : [
		"rgb(255, 255, 255)",
		"rgb(239, 225, 180)",
		"rgb(224, 198, 113)",
		"rgb(212, 175, 55)",
		"rgb(181, 148, 40)",
		"rgb(154, 125, 29)",
		"rgb(132, 106, 20)",
		"rgb(114, 90, 13)",
		"rgb(99, 78, 7)",
		"rgb(87, 67, 3)",
		"rgb(77, 59, 0)"
	]
}
interface ArrowProps {
	turn: number
	color: string
	margin: string
}
interface ArrowContainerProps {
	margin: string
}
const ArrowContainer = styled.div<ArrowContainerProps>`
	margin: ${props => props.margin};
`;
export function Arrow(props: ArrowProps) {
	return (
		<ArrowContainer margin={props.margin}>
			<svg style={{transform:`rotate(${props.turn}deg)`,height:"1.4rem"}} viewBox="-24 -6 48 26">
				<path d="M 0 0 L 12 10 A 0.7071 0.7071 0 0 1 10 12 L 0 3.6667 L -10 12 A 0.7 0.7 0 0 1 -12 10 Z" stroke-width="0" fill={props.color}/>
			</svg>
		</ArrowContainer>
	)
}