const QRCode = {
	desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis omnis harum dolorem amet quaerat odio veniam quia nisi, temporibus consectetur, pariatur quisquam esse explicabo perspiciatis corrupti suscipit, mollitia beatae dignissimos?",
	subscribeLink: "https://rapidapi.com/gold-apis-gold-apis-default/api/customisable-qr-codes/pricing",
	pricing: {
		prices: [
			{
				name: "Basic",
				price: 0.0,
				requests: 100,
				overage: 0.015
			},{
				name: "Pro",
				price: 8.0,
				requests: 2000,
				overage: 0.01
			},{
				name: "Ultra",
				price: 36.0,
				requests: 40000,
				overage: 0.01
			},{
				name: "Mega",
				price: 80.0,
				requests: 150000,
				overage: 0.01
			}
		],
		competitors: [
			{
				name: "LinQR",
				tiers: [
					{
						name: "Basic",
						price: 0.0,
						requests: 150,
						overage: "Hard limit"
					},{
						name: "Pro",
						price: 9.0,
						requests: 1500,
						overage: 0.015
					},{
						name: "Ultra",
						price: 39.0,
						requests: 20000,
						overage: 0.01
					},{
						name: "Mega",
						price: 99.0,
						requests: 100000,
						overage: 0.01
					}
				]
			},{
				name: "QRCode Monkey",
				tiers: [
					{
						name: "Basic",
						price: 9.0,
						requests: 200,
						overage: 0.04
					},{
						name: "Pro",
						price: 19,
						requests: 1000,
						overage: 0.02
					},{
						name: "Ultra",
						price: 99,
						requests: 10000,
						overage: 0.01
					}
				]
			}	
		]
	},
	spec: {
		"openapi": "3.0.2",
		"info": {
			"title": "Goldware QR Code API",
			"version": "0.1.0",
			"contact": {
				"email": "admin@goldware.co.uk"
			}
	
		},
		"paths": {
			"/qrcode/": {
				"post": {
					"summary": " Qrcode",
					"operationId": "_qrcode_qrcode__post",
					"requestBody": {
						"content": {
							"application/json": {
								"schema": {
									"$ref": "#/components/schemas/QR"
								}
							}
						},
						"required": true
					},
					"responses": {
						"200": {
							"description": "Returns the QR code as an image.",
							"content": {
								"application/json": {
									"schema": {}
								},
								"image/png": {}
							}
						},
						"422": {
							"description": "Validation Error",
							"content": {
								"application/json": {
									"schema": {
										"$ref": "#/components/schemas/HTTPValidationError"
									}
								}
							}
						}
					}
				}
			}
		},
		"components": {
			"schemas": {
				"HTTPValidationError": {
					"title": "HTTPValidationError",
					"type": "object",
					"properties": {
						"detail": {
							"title": "Detail",
							"type": "array",
							"items": {
								"$ref": "#/components/schemas/ValidationError"
							}
						}
					}
				},
				"QR": 
				{
					"title": "QR Code Generator",
					"required": [
						"text"
					],
					"type": "object",
					"properties": {
						"text": {
							"type": "string"
						},
						"color": {
							"description": "Color of the QR code, formatted in hex.",
							"type": "string",
							"min_length": 7,
							"max_length": 7,
							"pattern": "#[0-9a-fA-F]{6}",
							"default": "#000000"
						},
						"background_color": {
							"description": "Background color of the QR code, formatted in hex.",
							"type": "string",
							"min_length": 7,
							"max_length": 7,
							"pattern": "#[0-9a-fA-F]{6}",
							"default": "#ffffff"
						},
						"form": {
							"description": "Output format of QR code.",
							"type": "string",
							"enum": [
								"PNG",
								"JPEG",
								"SVG"
							],
							"default": "PNG"
						},
						"module_size": {
							"description": "Width in pixels of each module. Ignored if format is set to SVG. Setting this lower will decrease file size but may affect the look of rounded styles.",
							"type": "integer",
							"minimium": 1,
							"maximium": 63,
							"default": 20
						},
						"border": {
							"description": "Size of the 'quiet zone' surrounding the QR code in modules. A border of at least 4 is recommended for reliable scanning. ",
							"type": "integer",
							"minimium": 0,
							"maximium": 63,
							"default": 4
						},
						"error_correction": {
							"description": "Level of error correction. Higher settings will increase the size of the QR code but may improve scanning. 'low' is not recommended on custom QR codes.",
							"type": "string",
							"enum": [
								"high",
								"h",
								"medium",
								"m",
								"low",
								"l"
							],
							"default": "medium"
						},
						"module_style": {
							"description": "Style options. View documentation for more details.",
							"type": "string",
							"enum": [
								"default",
								"rounded",
								"gapped",
								"circle",
								"bars_v",
								"bars_h"
							],
							"default": "default"
						}
					}
				},
				"ValidationError": {
					"title": "ValidationError",
					"required": [
						"loc",
						"msg",
						"type"
					],
					"type": "object",
					"properties": {
						"loc": {
							"title": "Location",
							"type": "array",
							"items": {
								"anyOf": [
									{
										"type": "string"
									},
									{
										"type": "integer"
									}
								]
							}
						},
						"msg": {
							"title": "Message",
							"type": "string"
						},
						"type": {
							"title": "Error Type",
							"type": "string"
						}
					}
				}
			}
		}
	}
}
export default QRCode