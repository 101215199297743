import { Main, Text, H2 } from '../common/common.styles'

export function InterestTest() {
	return (
		<Main>
			<H2>Sorry,</H2>
			<Text>This API does not yet exist however thank you for registering your interest in this API</Text>
			<Text>All other pages in the menu bars do exist</Text>
		</Main>
	)
}