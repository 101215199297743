import { Routes, Route, Link } from 'react-router-dom'
import { InterestTest } from './apis/Interest_test'
import { Hdiv, Himg, theme, Box, Text } from './common/common.styles'
import Home from './Home'
import QRcode from './apis/QRcode'
import { API } from './apis/Api'
import { ThemeProvider } from 'styled-components'
import styled from 'styled-components'
import { SideNav } from './SideNav'
import { useState } from 'react'
import { useViewport } from './common/useViewport'

const HeadBar = styled(Hdiv)`
  background: ${props => props.theme.grey[3]};
  height: 5.14rem;
  box-sizing: border-box;
  padding: 8px;
  align-items: stretch;
  border-bottom: 2px solid ${props => props.theme.gold[3]};
  box-shadow: 0px 4px 4px #CCCCCC;
  position: fixed;
  top: 0px;
  z-index: 3;
  width: max(100vw, 100%);
`;
const LinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin: -8px 0px;
  padding: 0 8px;
  height: calc(100% + 16px);
  :hover {
    background: ${props => props.theme.grey[4]};
  }
`;
const LinkText = styled(Text)`
  font-size: 1.3rem;
  text-decoration: none;
  color: ${props => props.theme.grey[10]};
  font-family: sans-serif;
`;
const FootBar = styled(HeadBar)`
  height: 40px;
  border-bottom: none;
  box-shadow: none;
  border-top: 2px solid ${props => props.theme.gold[3]};
  position: relative;
`;
const Logo = styled(Himg)`
  margin-right: 8px;
`;
interface PageContainerProps {
  left: string
}
const PageContainer = styled(Box)<PageContainerProps>`
  padding-top: 5.14rem;
  min-height: calc(100vh - 300px);
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.left};
`;
interface BarLinkProps {
  children: string | JSX.Element | Array<JSX.Element>,
  to: string
}
function BarLink(props: BarLinkProps) {
  return (
    <LinkContainer to={props.to}>
      <LinkText>{props.children}</LinkText>
    </LinkContainer>
  )
}
const FlexPadding = styled.div<{grow: number}>`
  flex-grow: ${props => props.grow};
`;
function NavBar() {
  return (
    <HeadBar>
      <FlexPadding grow={1} />
      <Logo src="/logo.svg"></Logo>
      <FlexPadding grow={6} />
      <BarLink to="/">About</BarLink>
      <BarLink to="qrcode">QR codes</BarLink>
      <BarLink to="interest_test">Zip codes</BarLink>
      <FlexPadding grow={1} />
    </HeadBar>
  )
}
const FooterContainer = styled(Box)`
  width: max(100%, 100vw);
  z-index: 3;
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 2px solid ${props => props.theme.gold[3]};
  padding: 8px;
  background: ${props => props.theme.grey[2]};
`;
const FooterHeading = styled(Text)`
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
  display: block;
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 4px;
`;
const FooterLink = styled(Link)`
  font-size: 1.1rem;
  font-family: sans-serif;
  text-decoration: none;
  display: block;
  color: ${props => props.theme.grey[10]};
  &:hover {
    color: ${props => props.theme.gold[5]};
  }
`;
function Footer() {
  return (
    <FooterContainer>
      <div>
        <FooterHeading>Legal</FooterHeading>
        <FooterLink to="/#Privacy">Privacy</FooterLink>
      </div>
      <div>
        <FooterHeading>Contact</FooterHeading>
        <FooterLink to="/#Contact">Email</FooterLink>
      </div>
      <div>
        <FooterHeading>Other</FooterHeading>
        <FooterLink to="/#Billing">Billing</FooterLink>
      </div>
    </FooterContainer>
  )
}
export default function App() {

  var [side,setSide] = useState(true)

return (
    <ThemeProvider theme={theme}>
      <NavBar></NavBar>
      <SideNav open={side} setOpen={setSide}></SideNav>
      <PageContainer left={side ? "13.5rem" : "1.5rem"}> 
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/qrcode" element={<API {...QRcode}/>}/>
          <Route path="/interest_test" element={<InterestTest/>}/>
        </Routes>
      </PageContainer>
      <Footer></Footer>
    </ThemeProvider>
  )
}