import { useState } from 'react'
import styled from 'styled-components'
import { Box, Main, AccentText, Text, Hdiv, Himg, H2, Arrow } from '../common/common.styles'
import { RedocStandalone } from 'redoc'

export interface PricingProps {
	prices: Array<PriceTier>,
	competitors: Array<Competitor>
};
interface PriceTier {
	name: string,
	price: number,
	requests: number,
	overage: number | string
};
interface Competitor {
	name: string,
	tiers: Array<PriceTier>
};
interface PriceTableProps {
	width: number
}
const PriceTable = styled(Box)<PriceTableProps>`
	display:grid;
	grid-template-columns: ${props => "1fr ".repeat(props.width)};
	border: 1px solid ${props => props.theme.gold[4]};
	border-width: 1px 2px;
	margin: 12px 0;
	border-radius: 4px;
	box-shadow: 0px 4px 4px -4px grey;
	overflow-x: auto;
`;
interface TableItemProps {
	span?: string
};
const TableItem = styled(Box)<TableItemProps>`
	border-top: 1px solid ${props => props.theme.gold[4]};
	border-bottom: 1px solid ${props => props.theme.gold[4]};
	padding: 4px;
	grid-column: ${props => props.span || "initial"};
`;
const TableHeader = styled(AccentText)`
	font-size: 1.1rem;
	margin: -4px;
	margin-bottom: 0px;
`;
const TableMinorHeader = styled(TableHeader)`
	color: ${props => props.theme.grey[10]};
	background: ${props => props.theme.grey[3]};
	border-bottom: 1px solid ${props => props.theme.gold[4]};	
	white-space: nowrap;
`;
const TableSpanButton = styled(AccentText)`
	grid-column: span 5;
	border: 1px solid ${props => props.theme.gold[4]};
	cursor: pointer;
	display: flex;
	justify-content: center;
	&:hover {
		background: ${props => props.theme.gold[4]}
	}
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
`;
interface PriceProps {
	data: PriceTier
	span?: string
	competitor: boolean
}
function Price(props: PriceProps) {
	var data = props.data
	var overage: JSX.Element
	if (typeof data.overage === "string") {
		overage = <Text>{data.overage}</Text>
	} else {
		overage = <Text>${data.overage} per additional request</Text>
	} 
	var head = props.competitor ? <TableMinorHeader>{data.name}</TableMinorHeader> : <TableHeader>{data.name}</TableHeader>
	return(<TableItem span={props.span}>
		{head}
		<Text>${data.price.toLocaleString("en-UK",{minimumFractionDigits: 2})}</Text>
		<Text>{data.requests} requests</Text>
		{overage}
	</TableItem>)
}
function priceMap(prices: Array<PriceTier>,max_len: number,competitor: boolean) {
	var last = prices.length-1;
	return (prices.map((i,n) => {
		if (n === last) {
			return <Price data={i} span={"span "+(max_len-n)} competitor={competitor}></Price>
		}
		return <Price data={i} competitor={competitor}></Price>
	}))
}
export function Pricing(props: PricingProps) {
	
	const [showAdditonal, setAdditional] = useState(false)

	var maxLen = Math.max(
		props.prices.length,
		...props.competitors.map((i) => { return i.tiers.length })
	)
	const prices = priceMap(props.prices,maxLen,false)
	if (!showAdditonal) {
		return (
			<Box>
				<H2>Pricing</H2>
				<PriceTable width={maxLen}>
					{/*<TableHeadSpan>Pricing</TableHeadSpan>*/}
					{/*<TableItem>
						<Text>Goldware</Text>
					</TableItem>*/}
					{prices}
					<TableSpanButton onClick={()=>{setAdditional(true)}}>Show competitor prices <Arrow margin="-2px 0px -4px 0px"color="white" turn={180}></Arrow></TableSpanButton>
				</PriceTable>
				<Text>Unlimited plans are negotiable</Text>
			</Box>
		)
	}
	var compPrices = []
	for (let i of props.competitors) {
		compPrices.push(<TableItem>
			<TableMinorHeader>{i.name}</TableMinorHeader>
		</TableItem>)
		compPrices.push(...priceMap(i.tiers,maxLen,true))
	}
	return (
		<Box>
			<H2>Pricing</H2>
			<PriceTable width={maxLen + 1}>
				{/*<TableHeadSpan>Pricing</TableHeadSpan>*/}
				<TableItem>
					<TableHeader>Goldware</TableHeader>
					<Text>Best value</Text>
				</TableItem>
				{prices}
				{compPrices}
				<TableSpanButton onClick={()=>{setAdditional(false)}}>Hide Competitor prices <Arrow margin="-2px 0 -4px 0" color="white" turn={0}></Arrow></TableSpanButton>
			</PriceTable>
			<Text>Unlimited plans are negotiable</Text>
		</Box>
	)
}
const AButton = styled.a`
	border-radius: 4px;
	background: ${props => props.theme.gold[3]};
	border: 1px solid ${props => props.theme.gold[4]};
	padding: 4px;
	flex-grow: 1;
	height: 1.3rem;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-decoration: none; // Don't know why it has to be here instead of on the text
	&:hover {
		background: ${props => props.theme.gold[4]}
	}
`;
const SubscribeText = styled(AccentText)`
	padding: 0px 4px;
	margin-top: 2px; // Better alignment
	font-size: 1.1rem;
	background-color: inherit;
`;
interface SubscribeProps {
	href: string
};
export function Subscribe(props: SubscribeProps) {
	return (
		<AButton href={props.href}>
			<Himg src="rapid_api.png"></Himg>
			<SubscribeText>Subscribe to this API</SubscribeText>
		</AButton>
	)
}
const DescContainer = styled(Hdiv)`
	flex-wrap: wrap;
	column-gap: 8px;
`;
const Desc = styled(Text)`
	flex-basis: 300px;
	flex-grow: 100; // Really big so flex grow of other element has no effect
	text-align: justify;
`;
interface APIProps {
	desc: string | JSX.Element | Array<JSX.Element>;
	pricing: PricingProps;
	spec: any; //Open API spec
	subscribeLink: string;
}
export function API(props: APIProps) {

	console.log("API Render")

	return (
		<Main>
			<DescContainer>
				<Desc>{props.desc}</Desc>
				<Subscribe href={props.subscribeLink}></Subscribe>
			</DescContainer>
			<Pricing prices={props.pricing.prices} competitors={props.pricing.competitors}></Pricing>
			<RedocStandalone spec={props.spec} options={{
					disableSearch: true,
					hideLoading: true,
					hideSchemaTitles: true,
					hideHostname: true,
					theme: {
						sidebar: {
							width: "0",
						},
						typography: {
							fontSize: "1rem",
							code: {
								fontSize: "0.9rem",
							}
						},
			}}}></RedocStandalone>
			<Text>API documentation template provided by <a href="https://redocly.com/redoc">Redocly</a></Text>		
			<Box>
				<H2>Licensing</H2>
				<Text>The word QR Code is registered trademark of DENSO WAVE INCORPORATED in some countries</Text>
			</Box>
		</Main>
	)
}